<template>
  <div class="jumbotron w-100 shadow welcome-box">
    <h4 class="text-center text-light">
      <slot></slot>
    </h4>
    <p class="text-center text-light">
      <slot name="paragraph"></slot>
    </p>
  </div>
</template>

<script>
export default {
  name: "GradientBox",
};
</script>

<style scoped>
.welcome-box {
  background: #3744ac;
  background: linear-gradient(-45deg, #f8a69e, #e73c7e, #3744ac, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  border-radius: 0;
  position: relative;
  overflow: hidden;
}
@keyframes gradient {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  to {
    background-position: 0 50%;
  }
}
</style>
