<template>
  <div class="card-box animated fadeIn" @click="redirect" :class="bg">
    <div class="inner">
      <h4 class="text-right">
        <span v-if="!!title">{{ title }}</span>
        <VueCounter :end="counter" duration="6000" v-else-if="!title" />
      </h4>
      <h5>{{ description }}</h5>
    </div>
    <div class="icon">
      <i :class="icon"></i>
    </div>
    <router-link :to="route" class="card-box-footer">
      <span>عرض المزيد</span>
      <i class="fa fa-arrow-circle-right"></i>
    </router-link>
  </div>
</template>

<script>
import VueCounter from "@/components/UI/VueCounter";

export default {
  components: { VueCounter },
  props: {
    bg: { default: "bg-success" },
    counter: { default: 0 },
    title: {},
    description: {},
    icon: {},
    route: {},
  },
  methods: {
    redirect() {
      if (!!this.route) this.$router.replace(this.route);
    },
  },
};
</script>

<style scoped>
.card-box {
  position: relative;
  color: #fff;
  padding: 20px 10px 40px;
  margin: 20px 0;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  cursor: pointer;
  border-radius: 0;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.card-box:hover {
  text-decoration: none;
  color: #f1f1f1;
}

.card-box:hover .icon i {
  font-size: 80px;
  transition: 1s;
  -webkit-transition: 1s;
}

.card-box .inner {
  padding: 5px 10px 0 10px;
}

.card-box h3 {
  font-size: 27px;
  font-weight: bold;
  margin: 0 0 8px 0;
  white-space: nowrap;
  padding: 0;
  text-align: left;
}

.card-box p {
  font-size: 15px;
}

.card-box .icon {
  position: absolute;
  top: auto;
  bottom: 5px;
  right: 5px;
  z-index: 0;
  font-size: 50px;
  color: rgba(0, 0, 0, 0.15);
}

.card-box .card-box-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  text-align: center;
  padding: 3px 0;
  color: rgba(255, 255, 255, 0.8);
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
  text-decoration: none;
}

.card-box:hover .card-box-footer {
  background: rgba(0, 0, 0, 0.3);
}
</style>
